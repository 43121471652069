import { PageLoader } from "@chef/smart-components";
import { useRouter } from "next/router";
import { useEffect } from "react";

const App = () => {
  const router = useRouter();

  useEffect(() => {
    router.push("/app/start");
  }, [router]);

  return <PageLoader wholeScreen />;
};

export default App;
